body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}

body a {
  color: #fff !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.navbar {
  margin-bottom: 50px !important;
  padding: 20px 0 !important;
  background: #333333;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #dd1818, #333333);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #dd1818, #333333);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border: 0 !important;
  border-radius: 0 !important;
}

.list-inline li {
  color: #fff;
}

.users-info {
  text-align: center;
  margin-bottom: 30px;
}

.user-info-title ,
.company-info-title  {
  color: #dd1818;
  font-weight: bold;
  font-size: 17px;
}

.user-name,
.company-name {
  color: #000;
  font-size: 20px;
  text-transform: capitalize;
}

.footer-container {
  padding: 20px;
  margin-top: 40px;
  /* background-color: #373e4a; */
  background: #333333;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #dd1818, #333333);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #dd1818, #333333);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-icons {
  display: flex;
}

.footer-link {
  margin-left: 20px;
}

.footer-icons i {
  width: 30px;
  height: 30px;
  /* background: whitesmoke; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 20px;
}

.chart-title {
  text-align: center;
  font-size: 20px;
  color: #dc281e;
  font-weight: bold;
}

.ping-state {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 350px;
}

/* .ping-state>.state-icon>i {
  font-size: 90px;
  color: #fff;
} */

.ping-state>.state-icon>svg {
  font-size: 90px;
  color: #fff;
}

.ping-description {
  margin: 70px 0 20px 0;
  display: flex;
  justify-content: center;
}

.ping-box {
  display: flex;
  margin-right: 30px;
}

.ping-square {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 25px;
}

.low {
  background: '#56ab2f';
  background: linear-gradient(to right, #56ab2f, #a8e063);
}

.normal {
  background: '#fdc830';
  background: linear-gradient(to right, #fdc830, #f37335);
}

.high {
  background: '#f00000';
  background: linear-gradient(to right, #f00000, #dc281e);
}

.ping-none {
  background: '#000000';
  background: linear-gradient(to right, #000000, #434343);
}

.ping-text {
  color: #414345;
  font-weight: bold;
}

.info {
  color: #fff;
  font-size: 11px;
}

.logout {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.navbar {
  display: flex;
  align-items: center;
}

/* chart */

.recharts-wrapper .recharts-surface .recharts-layer .recharts-text {
  font-weight: bold !important;
  font-style: italic !important;
  color: red !important;
}

svg {
  margin: 0 auto !important;
}